body {
    background-color: var(--background);
    color: var(--text);
    overflow-y: scroll;
}

.App {
    display: grid;
    justify-content: center;
}

.App-header {
    text-align: center;
    padding-bottom: 0;
    margin-bottom: 0;
}

#main-header {
    font-size: 2.5rem;
    margin-bottom: 0;
    padding-bottom: 0;
}

.author-title {
    padding-top: 0;
    margin-top: 0;
    font-weight: 500;
}

.author-title a {
    color: var(--primary);
    text-decoration: none;
}

.author-title a:hover {
    text-decoration: underline;
}

.error-message-container {
    height: 25px;
    overflow: hidden;
    max-width: 900px;
    padding-left: 20px;
    padding-right: 22px;
}

.error-message {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s;
    background-color: #ffcccc;
    border: 1px solid #cc0000;
    color: #cc0000;
}

.error-message.visible {
    visibility: visible;
    opacity: 1;
    transition: visibility 0s linear, opacity 0.2s;
}

.footer {
    text-align: center;
    font-size: 0.9rem;
    color: #666;
    margin-top: 20px;
    padding: 10px 0;
    border-top: 1px solid #ddd;
    max-width: 880px;
}

.footer a {
    color: var(--primary);
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}