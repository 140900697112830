table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: var(--background);
}

th,
td {
    border: 1px solid var(--secondary);
    padding: 8px;
    text-align: left;
    font-size: 15px;
    vertical-align: middle;
    color: var(--text);
}

th {
    background-color: var(--secondary);
}

.ticker-label {
    font-weight: 400;
}

#instruc-but {
    width: 180px;
}

input[type="number"] {
    width: 80%;
    padding: 5px;
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0px;
    font-size: 15px;
    text-align: left;
    vertical-align: middle;
    color: var(--text);
}

input[type="text"] {
    width: 80px;
    margin-left: 10px;
    font-size: 1.375rem;
    padding: 5px;
    font-weight: 500;
    border: 2px solid #ddd;
    border-radius: 6px;
    background-color: #f9f9f9;
    text-transform: uppercase;
    transition: all 0.3s ease;
}

input[type="text"]:hover {
    border-color: var(--primary);
    box-shadow: 0 4px 8px rgba(var(--primary), 0.2);
}
  
input[type="text"]:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 4px 8px rgba(var(--primary), 0.3);
}

input[type="text"]::placeholder {
    color: #aaa;
}

td:nth-child(1) {
    width: 260px;
}

td:nth-child(2) {
    width: 90px;
}

button {
    font-size: 1.2rem;
    padding: 8px 11px;
    margin-left: 10px;
    background-color: var(--primary);
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.15s ease-in-out;
}

button:hover {
    transform: scale(1.05, 1);
}

label {
    animation: fadeIn 0.5s ease-in-out;
}

.inputCell {
    padding: 3px;
}

.inputCell:hover {
    background-color: #E2E2E2;
}

.input-cell-percentage {
    padding: 3px;
    padding-right: 8px;
}

.input-cell-percentage:hover {
    background-color: #E2E2E2;
}

.percentage-cell-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ticker-section {
    padding: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.25rem;
    font-weight: bold;
    max-width: 900px;
    width: 100%;
    padding-right: 0;
    margin-right: 0;
    margin-bottom: 0;
    padding-bottom: 0.5rem;
    margin-top: 1rem;
}

.status-indicator {
    display: inline-block;
    margin: 0px 13px;
    font-weight: 500;
    width: 160px;
}

.company-info-section {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-bottom: 0.5rem;
    margin-top: 1.5rem;
    font-size: 1.6rem;
    max-width: 880px;
    width: 100%;
    vertical-align: bottom;
    border-bottom: 1px solid #aaa;
}

.company-info-left,
.company-info-right {
    color: #333;
    display: flex;
    align-items: center;
}

.company-info-left .company-name {
    font-weight: bold;
    color: var(--text);
    text-decoration: none;
    display: inline-flex;
    align-items: center;
}

.company-info-left i {
    margin-left: 6px;
    font-size: 0.9rem;
}

.company-info-left i {
    margin-left: 6px;
    font-size: 0.8rem;
    color: var(--primary);
    vertical-align: middle;
}

.company-info-right {
    margin-left: auto;
    white-space: nowrap;
    font-size: 1.3rem;
}

.company-industry {
    margin-left: 8px;
    font-weight: bold;
    color: var(--text);
}

.flex-container {
    display: flex;
    justify-content: space-between;
}

.tooltip {
    position: relative;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 0.9rem;
}

.tooltip::after {
    content: '?';
    position: absolute;
    margin-left: 0.5rem;
    width: 15px;
    height: 15px;
    background-color: #E0E0E0;
    border: 1px solid #ccc;
    border-radius: 50%;
    text-align: center;
    line-height: 13px;
    font-size: 13px;
}

.tooltip:hover::before {
    content: attr(data-tooltip);
    position: absolute;
    top: -25px;
    left: 25px;
    padding: 5px;
    background-color: var(--text);
    color: var(--background);
    border-radius: 4px;
    opacity: 0.9;
    font-size: 12px;
    white-space: nowrap;
    pointer-events: none;
    opacity: 1;
    transform: translateY(0);
}

#tooltip-header::after {
    background-color: var(--primary);
    font-weight: 500;
    color: var(--background);
}

#tooltip-header:hover::before {
    font-weight: 400;
}

.instructions {
    margin: 0;
    padding: 0;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-in-out, visibility 0s linear 0.2s;
    text-align: left;
}

.instructions p {
    font-weight: bold;
}

.instructions ul {
    list-style: disc;
}

.instructions.show {
    max-height: 500px;
    transition: max-height 0.3s ease-in-out;
}
