@font-face {
    font-family: 'Funnel Sans';
    src: url('./fonts/FunnelSans-Regular.ttf') format('truetype');
    font-weight: 100 900; /* Range */
    font-style: normal;
}

@font-face {
    font-family: 'Funnel Sans';
    src: url('./fonts/FunnelSans-Italic.ttf') format('truetype');
    font-weight: 100 900; /* Range */
    font-style: italic;
}

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

body {
    animation: fadeUp 0.5s ease-out;
    animation-fill-mode: forwards;
    opacity: 0;
    margin: 0;
    font-family: 'Funnel Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/* @media (prefers-color-scheme: light) { */
    :root {
        --text: #080708;
        --background: #EFECEF;
        --primary: #4962A8;
        --secondary: #C5CCE4;

        --scrollbar: rgba(148, 148, 148, 0.6);
        --scrollbar-hover: rgba(148, 148, 148, 0.8);
        --selection: #bcd7ec;

    }
/* } */

/* @media (prefers-color-scheme: dark) {
    :root {
        --text: #dedef0;
        --background: #1e1e1f;
        --primary: #3b5088;
        --secondary: #20208b;
    }
} */

::-webkit-scrollbar {
    background-color: var(--background);
}

::-webkit-scrollbar-thumb { 
    background-color: var(--scrollbar);
    transition: background-color 0.3s ease;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(148, 148, 148, 0.8);
}  

::selection {
    background: var(--selection);
    color:var(--text);
}
